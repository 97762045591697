(function() {
  const videoBanners = [].slice.call(document.querySelectorAll('.video-banner'));
  
  videoBanners.forEach(banner => {
    const playButton = banner.querySelector('.video-banner__play');
    const embedded_video = banner.parentNode.querySelector('iframe');
    const video_mp4 = document.getElementById("alt-grid-video");
    
    playButton.addEventListener('click', function() {
      if(embedded_video){
        if (embedded_video.getAttribute('src').indexOf('enablejsapi') !== -1) {
          const sfccSrc = embedded_video.getAttribute('src').split('?enablejsapi')[0];
          embedded_video.setAttribute('src', sfccSrc + '?autoplay=1');
        } else {
          const src = embedded_video.getAttribute('src');
          embedded_video.setAttribute('src', src + '?autoplay=1');
        }
      }
      if(video_mp4){
        video_mp4.play();
      }

      setTimeout(() => {
        banner.classList.add('is-playing');
      }, 150)
    });
  });
})();

